import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { RootState } from '../reducers/store'

export type AppDispatch = ThunkDispatch<RootState, void, AnyAction>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CustomThunkDispatch = (arg0: { payload: any; type: string }) => void

export enum RoleType {
  SUPER_ADMIN = 'Super Admin',
  PLATFORM_MGMT = 'Platform Management',
  ACCESS_MGMT = 'Access Management',
  GENERAL_ADMIN = 'General Admin',
}

export type User = {
  name: string
  access_code: string
  threat_detected: boolean
  threat_message?: string
  screen_lock: boolean
  biometrics: boolean
  iverify_version: string
  version_message?: string
  guides_completed: string
  device: string
  notifications: boolean
  os: string
  added: string
}

export type OrganizationLookup = {
  sso: boolean
  slug: string
}

export type SessionState = {
  user: UserState
  organization: OrganizationState
  portalPreferences: PortalPreferences
  error?: string
}

export type UserState = {
  name: string
  email: string
  roles: string
}

export type OrganizationState = {
  name: string
  slug: string
  ssoProvider: string
  redirectUrl: string
}

export type PortalPreferences = {
  orgId: number
  apiAccess: boolean
  threatHunterAccess: boolean
  localDnsFiltering: boolean
  threatIntelAccess: boolean
}

export type ErrorState = {
  type: string
  severity?: SeverityType
  message: string
  description: string
}

export const enum SeverityType {
  Info = 'INFO',
  Warning = 'WARNING',
  Error = 'ERROR',
}

export type UpdatePasswordPayload = {
  password: string
  requestToken: string
}

export type UpdatePasswordResponse = {
  error?: string
}
