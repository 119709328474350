import Server from '../../api/server'
import { GENERATE_PRESIGNED_URL_ROUTE } from '../../strings/api-consts'
import { CustomThunkDispatch } from '../../types/UserTypes'
import * as Sentry from '@sentry/react'

const server = new Server()

const fetchPresignedUrl = (bucket: string, fileName: string) => async (dispatch: CustomThunkDispatch) => {
  return server
    .post(GENERATE_PRESIGNED_URL_ROUTE, { bucket: bucket, fileName: fileName }, dispatch)
    .then((res) => {
      if (!res) {
        throw new Error('Response is undefined')
      }
      return res.json()
    })
    .then((response: { url: string }) => {
      return response.url
    })
    .catch((err) => {
      Sentry.captureMessage(err.message)
      return null
    })
}

export const downloadAdversaryReportPdf = (fileName: string) => async (dispatch: CustomThunkDispatch) => {
  const bucket = import.meta.env.VITE_ADVERSARY_BUCKET
  const presignedUrl = await fetchPresignedUrl(bucket, fileName)(dispatch)

  if (!presignedUrl) {
    return false
  }

  const link = document.createElement('a')
  link.href = presignedUrl
  link.download = fileName
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  return true
}
