import { IconAdjustmentsHorizontal, IconPower, IconSettings2, IconX } from '@tabler/icons-react'
import { ConfigurationOption, IntegrationDetails, IntegrationValue } from '../../types/IntegrationTypes'
import { useEffect, useState } from 'react'
import { Switch } from '@radix-ui/themes'
import toast from 'react-hot-toast'
import { useAppDispatch } from '../../reducers/store'
import {
  deleteIntegration,
  fetchIntegrationsList,
  updateIntegrationsConfig,
  updateIverifyConfig,
} from '../../reducers/integrations'
import IntegrationRemoveModal from './IntegrationRemoveModal'
import { validateField } from '../../utils/validate'

interface IIntegrationPopoutProps {
  popoutIntegration: IntegrationDetails | undefined
  setShowPopout: (showPopout: boolean) => void
}

function IntegrationPopout(props: IIntegrationPopoutProps) {
  const { popoutIntegration, setShowPopout } = props
  const di = useAppDispatch()

  const [isDirty, setIsDirty] = useState(false)

  const [integrationEnabled, setIntegrationEnabled] = useState(true)
  const [iverifyOptionsEnabled, setIverifyOptionsEnabled] = useState<{ [key: string]: boolean }>({})
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)

  useEffect(() => {
    popoutIntegration?.configurationOptions[0].value !== null
      ? setIntegrationEnabled(true)
      : setIntegrationEnabled(false)

    popoutIntegration?.iverifyOptions.forEach((option) => {
      option.value !== null
        ? setIverifyOptionsEnabled((prevState) => ({ ...prevState, [option.serverValue]: true }))
        : setIverifyOptionsEnabled((prevState) => ({ ...prevState, [option.serverValue]: false }))
    })
  }, [])

  const handleInputChange = () => {
    setIsDirty(true)
  }

  const saveIntegrationSettings = async (formData: IntegrationValue[]) => {
    const payload = {
      integrationValues: JSON.stringify(formData),
      integrationServerValue: popoutIntegration!.serverValue,
    }
    const [successful, response] = await di(updateIntegrationsConfig(payload))

    if (successful) {
      toast.success('Integration settings saved successfully', { duration: 6000 })
      di(fetchIntegrationsList())
      setIntegrationEnabled(true)
      setIsDirty(false)
    } else {
      if (response !== undefined) {
        toast.error(response, { duration: 6000 })
      } else {
        toast.error('Failed to save integration settings', { duration: 6000 })
      }
    }
  }

  const saveIverifySettings = async (formData: IntegrationValue[]) => {
    const payload = {
      integrationValues: JSON.stringify(formData),
      integrationServerValue: popoutIntegration!.serverValue,
    }
    const successful = await di(updateIverifyConfig(payload))
    if (successful) {
      toast.success('Integration options saved successfully', { duration: 6000 })
      di(fetchIntegrationsList())
      setIntegrationEnabled(true)
      setIsDirty(false)
    } else {
      toast.error('Failed to save integration options', { duration: 6000 })
    }
  }

  const generateInput = (option: ConfigurationOption) => {
    switch (option.type) {
      case 'text_input':
        return (
          <input
            type="text"
            name={option.serverValue}
            defaultValue={option.value || undefined}
            placeholder={option.placeholder}
            className="h-8 border rounded p-2 w-full bg-[#00003B0D] text-sm text-neutral-12"
            onChange={handleInputChange}
          />
        )
      case 'select':
        return (
          <select
            name={option.serverValue}
            defaultValue={option.value || undefined}
            className="h-8 border rounded pl-2 w-full bg-[#00003B0D] text-sm text-neutral-12"
            onChange={handleInputChange}
          >
            {option.options?.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        )
      default:
        return <p>Not supported</p>
    }
  }

  const removeIntegration = async () => {
    const deleted = await di(deleteIntegration({ integrationServerValue: popoutIntegration!.serverValue }))
    if (deleted) {
      toast.success('Integration deleted successfully', { duration: 6000 })
      di(fetchIntegrationsList())
      setShowPopout(false)
    } else {
      toast.error('Failed to delete integration', { duration: 6000 })
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault()
        e.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty])

  return (
    <>
      <div
        className="bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-50"
        onClick={() => {
          if (isDirty) {
            if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
              setShowPopout(false)
            }
          } else {
            setShowPopout(false)
          }
        }}
      >
        <div
          className={`top-0 my-[7px] mr-[11px] rounded-lg h-[calc(100vh_-_14px)] fixed w-[600px] right-0 bg-white font-['Open Sans'] z-50 border-t border-l shadow-2xl overflow-y-auto p-4`}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className="w-full flex justify-between">
            <p className="text-black text-lg font-medium mt-1">{popoutIntegration!.name}</p>
            <div
              className="h-8 w-8 cursor-pointer border rounded flex items-center justify-center"
              onClick={() => {
                if (isDirty) {
                  if (window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
                    setShowPopout(false)
                  }
                } else {
                  setShowPopout(false)
                }
              }}
            >
              <IconX size={24} />
            </div>
          </div>

          {/* Integration Settings */}
          <div className="border rounded-lg mt-6">
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const formData = new FormData(e.target as HTMLFormElement)
                const data = Array.from(formData.entries()).map(([key, value]) => ({
                  serverValue: key,
                  value: value.toString().trim(),
                  label: popoutIntegration?.configurationOptions.find((option) => option.serverValue === key)?.label,
                  format: popoutIntegration?.configurationOptions.find((option) => option.serverValue === key)?.format,
                  required: popoutIntegration?.configurationOptions.find((option) => option.serverValue === key)
                    ?.required,
                  minLength: popoutIntegration?.configurationOptions.find((option) => option.serverValue === key)
                    ?.minLength,
                  maxLength: popoutIntegration?.configurationOptions.find((option) => option.serverValue === key)
                    ?.maxLength,
                }))

                const errors: string[] = []
                data.forEach((field) => {
                  const error = validateField(field)
                  if (error) {
                    errors.push(error)
                  }
                })

                if (errors.length > 0) {
                  toast.error(errors.join('\n'), { duration: 6000 })
                  return
                }

                saveIntegrationSettings(data)
              }}
            >
              <div className="w-full flex justify-between bg-neutral-1 rounded-t-lg border-b pb-3">
                <div className="flex ml-3 mt-3">
                  <IconSettings2 size={24} className="mt-1" />
                  <p className="text-black font-medium ml-2 mt-1">Integration Settings</p>
                </div>
                <button
                  className="h-8 px-3 cursor-pointer border rounded flex items-center justify-center mr-3 mt-3"
                  type="submit"
                >
                  Save
                </button>
              </div>

              <div className="p-4">
                {popoutIntegration?.configurationOptions.map((option) => (
                  <div className="flex justify-between mb-4" key={option.serverValue}>
                    <p className="w-[160px] truncate text-black text-sm">{option.label}</p>
                    <div className="flex-1 -mt-1.5">{generateInput(option)}</div>
                  </div>
                ))}
              </div>
            </form>
          </div>

          {integrationEnabled && (
            <>
              {/* Integration Options */}
              <div className="border rounded-lg mt-6">
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                    const formData = new FormData(e.target as HTMLFormElement)
                    const data = Array.from(formData.entries()).map(([key, value]) => ({
                      serverValue: key,
                      value: value.toString().trim(),
                    }))

                    let error = false
                    data.forEach(({ value }) => {
                      if (!value) {
                        error = true
                      }
                    })

                    if (error) {
                      toast.error('Please fill out all fields', { duration: 6000 })
                      return
                    }
                    saveIverifySettings(data)
                  }}
                >
                  <div className="w-full flex justify-between bg-neutral-1 rounded-t-lg border-b pb-3">
                    <div className="flex ml-3 mt-3">
                      <IconAdjustmentsHorizontal size={24} className="mt-1" />
                      <p className="text-black font-medium ml-2 mt-1">Integration Options</p>
                    </div>
                    <button
                      className="h-8 px-3 cursor-pointer border rounded flex items-center justify-center mr-3 mt-3"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                  <div className="p-4">
                    {popoutIntegration?.iverifyOptions.map((option) => (
                      <div key={option.serverValue}>
                        <div className="flex justify-between mb-4">
                          <p className="w-[160px] truncate text-black text-sm">{option.label}</p>
                          <Switch
                            size="2"
                            defaultChecked={option.value !== null}
                            onCheckedChange={(e) => {
                              setIverifyOptionsEnabled({
                                ...iverifyOptionsEnabled,
                                [option.serverValue]: e,
                              })
                              setIsDirty(true)
                            }}
                          />
                        </div>
                        {iverifyOptionsEnabled[option.serverValue] && (
                          <div className="flex-1 -mt-1.5">{generateInput(option)}</div>
                        )}
                      </div>
                    ))}
                  </div>
                </form>
              </div>

              {/* Deactivate Integration */}
              <div className="border rounded-lg mt-6">
                <div className="w-full flex justify-between bg-neutral-1 rounded-t-lg border-b pb-3">
                  <div className="flex ml-3 mt-3">
                    <IconPower size={24} className="mt-1" />
                    <p className="text-black font-medium ml-2 mt-1">Deactivate Integration</p>
                  </div>
                </div>
                <div className="p-4">
                  <p className="text-neutral-11 text-sm">
                    By deactivating this integration, any connection between this service and your organization will be
                    removed. Do so at your own risk.
                  </p>
                  <div
                    className="h-8 w-[194px] cursor-pointer border border-Red-9 text-Red-9 rounded flex items-center justify-center mt-3"
                    onClick={() => setShowDeactivateModal(true)}
                  >
                    Deactivate Integration
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {showDeactivateModal && (
        <IntegrationRemoveModal
          name={popoutIntegration!.name}
          setShowModal={setShowDeactivateModal}
          handleRemoveIntegration={removeIntegration}
        />
      )}
    </>
  )
}

export default IntegrationPopout
