import RainbowRoninImage from '../../assets/rainbow-ronin.png'
import FluffyRoninImage from '../../assets/fluffy-ronin.png'
import FlatulentToadImage from '../../assets/flatulent-toad.png'

const imageMapping: Record<'Rainbow Ronin' | 'Fluffy Ronin' | 'Flatulent Toad', string> = {
  'Rainbow Ronin': RainbowRoninImage,
  'Fluffy Ronin': FluffyRoninImage,
  'Flatulent Toad': FlatulentToadImage,
}

export default imageMapping
