import { CheckIcon, Cross2Icon } from '@radix-ui/react-icons'
import AlertIcon from '../../icons/AlertIcon'
import { DeviceInformation } from '../../types/DeviceTypes'
import { Button, Progress, Tooltip } from '@radix-ui/themes'
import { timeSince } from '../../utils/dates'
import './styles.css'
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { fetchDeviceDetails, updateDeviceName } from '../../reducers/devices'
import toast from 'react-hot-toast'
import { IconPencil } from '@tabler/icons-react'
import SideBarSkeleton from '../skeleton/SideBarSkeleton'

interface IPopoutProps {
  deviceId: string
  setShowPopout: (show: boolean) => void
  setClickedInputs: (clickedInputs: DeviceInformation[]) => void
  setShowRemoveDeviceModal: (show: boolean) => void
  handleReloadingDevicesAfterUpdate: () => void
}

const DevicePopoutContent = (props: IPopoutProps) => {
  const { deviceId, setShowPopout, setClickedInputs, setShowRemoveDeviceModal, handleReloadingDevicesAfterUpdate } =
    props
  const [editingName, setEditingName] = useState<boolean>(false)
  const [updatedName, setUpdatedName] = useState<string>('')
  const [actualDeviceName, setActualDeviceName] = useState<string>('')
  const [deviceDetails, setDeviceDetails] = useState<DeviceInformation | null>(null)
  const di = useAppDispatch()

  const handleBooleanValues = (value: boolean) => {
    return value ? 'Yes' : 'No'
  }

  const calculatePercentage = (value: string) => {
    const [numerator, denominator] = value.split('/')
    return (parseInt(numerator) / parseInt(denominator)) * 100
  }

  const setNewDeviceName = async (accessCode: string) => {
    const updated = await di(updateDeviceName(accessCode, updatedName))
    if (updated) {
      toast.success('Device name updated successfully')
      setEditingName(false)
      handleReloadingDevicesAfterUpdate()
      setActualDeviceName(updatedName)
    } else {
      toast.error('Failed to update device name')
    }
  }

  useEffect(() => {
    if (deviceId) {
      fetchDeviceDetailsWithDeviceId(deviceId)
    }
  }, [deviceId])

  const fetchDeviceDetailsWithDeviceId = async (deviceId: string) => {
    const fetchedDetails = await di(fetchDeviceDetails(deviceId))
    if (!fetchedDetails) {
      return
    }
    setDeviceDetails(fetchedDetails)
    setUpdatedName(fetchedDetails.deviceName === null ? fetchedDetails.name : fetchedDetails.deviceName)
    setActualDeviceName(fetchedDetails.deviceName === null ? fetchedDetails.name : fetchedDetails.deviceName)
  }

  const booleanToYesNo = (value?: boolean) => {
    if (value === undefined || value === null) return 'Unknown'
    return value ? 'Yes' : 'No'
  }

  return (
    <>
      {!deviceDetails && <SideBarSkeleton />}
      {deviceDetails && (
        <>
          {deviceDetails.threats && (
            <div className="w-full h-[52px] flex bg-[#ff10100f] justify-center items-center border-b">
              <AlertIcon />
              <p className="text-xs font-semibold mt-0.5">This device is showing signs of compromise</p>
            </div>
          )}
          {/* Name */}
          <div className="w-full">
            <div className="w-full min-h-20 border-b flex px-6">
              <div className="w-3/4 flex flex-col justify-center">
                {!editingName && (
                  <Tooltip content="Click to edit the device name" className="max-w-[240px]">
                    <div
                      className="text-black text-lg font-semibold leading-normal whitespace-normal max-two-lines p-2 rounded-lg hover:bg-tableHeaderGrey mt-2 cursor-pointer flex gap-1"
                      onClick={() => setEditingName(true)}
                    >
                      {actualDeviceName}
                      <IconPencil className="h-6 w-6 text-neutral-10" />
                    </div>
                  </Tooltip>
                )}
                {editingName && (
                  <>
                    <input
                      className="text-black text-lg font-semibold leading-normal whitespace-normal max-two-lines p-[7px] rounded-lg border border-PurpleBlue-10 mt-2"
                      onClick={() => setEditingName(true)}
                      type="text"
                      value={updatedName}
                      onChange={(e) => setUpdatedName(e.target.value)}
                      maxLength={120}
                    />
                    <div className="flex justify-end mt-1">
                      <Cross2Icon
                        onClick={() => setEditingName(false)}
                        className="h-8 w-8 border border-navButtonBgGrey rounded-lg p-2 cursor-pointer mr-1"
                      />
                      {updatedName !== '' && (
                        <CheckIcon
                          onClick={() => setNewDeviceName(deviceDetails.code)}
                          className="h-8 w-8 border border-navButtonBgGrey rounded-lg p-2 cursor-pointer"
                        />
                      )}
                    </div>
                  </>
                )}
                <div className="text-black text-opacity-50 text-sm font-semibold leading-normal px-2 mb-2">
                  {deviceDetails.code}
                </div>
              </div>
              <div className="w-1/4 h-20 flex justify-end items-center float-right pr-2">
                <div
                  className="w-6 h-6 cursor-pointer"
                  onClick={() => {
                    setShowPopout(false)
                  }}
                >
                  <Cross2Icon className="w-6 h-6" />
                </div>
              </div>
            </div>
            {/* Version */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">iVerify Version</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                {!deviceDetails.isLatestVersion && <AlertIcon />}
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {deviceDetails.version}
                </div>
              </div>
            </div>
            {/* Version */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">OS</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                {!deviceDetails.latestOSVersion && <AlertIcon />}
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {deviceDetails.os}
                </div>
              </div>
            </div>
            {/* Screen Lock */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Screen Lock</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {handleBooleanValues(deviceDetails.screenLock)}
                </div>
              </div>
            </div>
            {/* Biometrics */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Biometrics</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {handleBooleanValues(deviceDetails.biometrics)}
                </div>
              </div>
            </div>
            {/* Notifications */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Notifications</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {deviceDetails.isNotificationsEnabled ? 'Enabled' : 'Disabled'}
                </div>
              </div>
            </div>
            {/* Smishing */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Smishing Enabled</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {booleanToYesNo(deviceDetails.isSmishingEnabled)}
                </div>
              </div>
            </div>
            {deviceDetails.platform === 'iOS' && (
              <>
                {/* Lockdown Enabled */}
                <div className="w-full h-12 border-b flex px-8">
                  <div className="w-1/2 h-12 flex flex-col justify-center">
                    <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">
                      Lockdown Enabled{' '}
                    </div>
                  </div>
                  <div className="w-1/2 h-12 flex justify-end items-center float-right">
                    <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                      {deviceDetails.isLockdownEnabled ? 'Enabled' : 'Disabled'}
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* Scan */}
            <div className="w-full border-b px-8">
              <div className="flex">
                <div className="w-1/2 h-12 flex flex-col justify-center">
                  <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">
                    Latest Insecure Scan
                  </div>
                </div>
                <div className="w-1/2 h-12 flex justify-end items-center float-right">
                  <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                    {timeSince(deviceDetails.latestInsecureScanDate)}
                  </div>
                </div>
              </div>
              {deviceDetails.threats && (
                <div className="w-full mt-2 mb-6">
                  <div className="w-[335px] min-h-[165px] bg-zinc-300 bg-opacity-40 overflow-y-auto p-4 rounded">
                    {deviceDetails.threats.map((threat) => (
                      <p key={threat.code} className="text-sm">
                        • {threat.description}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {/* Recent */}
            <div className="w-full h-12 border-b flex px-8">
              <div className="w-1/2 h-12 flex flex-col justify-center">
                <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">Recent Scan</div>
              </div>
              <div className="w-1/2 h-12 flex justify-end items-center float-right">
                <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                  {timeSince(deviceDetails.latestScanDate)}
                </div>
              </div>
            </div>
            {/* Guides */}
            <div className="w-full h-24 border-b px-8">
              <div className="flex">
                <div className="w-1/2 h-12 flex flex-col justify-center">
                  <div className="text-black text-sm font-normal font-['Open Sans'] leading-normal">
                    Guides Completed
                  </div>
                </div>
                <div className="w-1/2 h-12 flex justify-end items-center float-right">
                  <div className="text-black text-sm font-semibold font-['Open Sans'] leading-normal">
                    {deviceDetails.guidesCompleted && deviceDetails.guidesCompleted !== '0'
                      ? deviceDetails.guidesCompleted
                      : '0/40'}
                  </div>
                </div>
              </div>
              <div className="w-full mt-2">
                <Progress
                  color="green"
                  value={calculatePercentage(
                    deviceDetails.guidesCompleted && deviceDetails.guidesCompleted !== '0'
                      ? deviceDetails.guidesCompleted
                      : '0/40'
                  )}
                  size="3"
                  className="h-3"
                />
              </div>
            </div>
            <div className="h-20 flex items-center mt-4">
              <Button
                className="h-10 bg-PurpleBlue-10 rounded-lg my-5 mr-8 ml-auto cursor-pointer"
                onClick={() => {
                  setShowPopout(false)
                  setClickedInputs([deviceDetails])
                  setShowRemoveDeviceModal(true)
                }}
              >
                Remove Device
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default DevicePopoutContent
