import { useDispatch, useSelector } from 'react-redux'
import SettingsNavMenu from './SettingsNavMenu'
import { selectSettings, setCurrentPage } from '../../reducers/settings'
import AdminSettings from './Admin'
import { useNavigate } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import SsoSettings from './SSO'
import ScimSettings from './SCIM'
import MdmSettings from './MDM'
import NotificationSettings from './Notifications'
import ThreatHunterSettings from './ThreatHunter'
import { selectUser, setSession } from '../../reducers/users'
import { useEffect } from 'react'
import AuditLogs from './AuditLogs'
import WebhookSettings from './Webhooks'
import ApiSettings from './API'
import ConditionalAccessSettings from './ConditionalAccess'
import { getRoles, permitsRole } from '../../utils/roles'
import { RoleType } from '../../types/UserTypes'

function SettingsWrapper() {
  const { currentPage } = useSelector(selectSettings)
  const { session } = useSelector(selectUser)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const storedSettingsPage = localStorage.getItem('SETTINGS_PAGE_COOKIE')
  const roles = getRoles(session)
  const rolePermitsAdmins = permitsRole(roles, [RoleType.SUPER_ADMIN])
  const rolePermitsPlatform = permitsRole(roles, [RoleType.PLATFORM_MGMT])

  useEffect(() => {
    if (storedSettingsPage) {
      dispatch(setCurrentPage(storedSettingsPage))
    } else {
      const defaultPage = rolePermitsAdmins ? 'ADMIN' : rolePermitsPlatform ? 'SSO' : 'CONDITIONAL_ACCESS'
      localStorage.setItem('SETTINGS_PAGE_COOKIE', currentPage ? currentPage : defaultPage)
    }
  }, [])

  const selectMainContent = () => {
    switch (currentPage) {
      case 'ADMIN':
        return <AdminSettings />
      case 'AUDIT':
        return <AuditLogs />
      case 'SSO':
        return <SsoSettings />
      case 'WEBHOOKS':
        return <WebhookSettings />
      case 'API':
        return <ApiSettings />
      case 'SCIM':
        return <ScimSettings />
      case 'MDM':
        return <MdmSettings />
      case 'NOTIFICATION':
        return <NotificationSettings />
      case 'CONDITIONAL_ACCESS':
        return <ConditionalAccessSettings />
      case 'THREAT_HUNTER':
        return <ThreatHunterSettings />
      default:
        return null
    }
  }

  const selectTitle = () => {
    switch (currentPage) {
      case 'ADMIN':
        return 'Administrators'
      case 'AUDIT':
        return 'Audit Logs'
      case 'SSO':
        return 'Single Sign On Provider'
      case 'WEBHOOKS':
        return 'Webhooks'
      case 'API':
        return 'API Access'
      case 'SCIM':
        return 'SCIM Configuration'
      case 'MDM':
        return 'MDM Configuration'
      case 'NOTIFICATION':
        return 'Notifications'
      case 'CONDITIONAL_ACCESS':
        return 'Conditional Access'
      case 'THREAT_HUNTER':
        return 'Provision Threat Hunter access'
      default:
        return 'Administrators'
    }
  }

  useEffect(() => {
    const localSession = localStorage.getItem('session') as string
    if (!session && localSession === '{}') {
      localStorage.setItem('session', '{}')
      localStorage.removeItem('SSO_CONFIGURED_COOKIE')
      localStorage.removeItem('SSO_ONBOARDING_COOKIE')
      dispatch(setSession(undefined))
      navigate('/login')
    }
  }, [session])

  useEffect(() => {
    const session = localStorage.getItem('session') as string
    if (session === '{}') {
      navigate('/login')
    }
  }, [])

  return (
    <>
      <div className="w-full h-screen mx-auto flex gap-10 light bg-neutral-1">
        {/* Nav Menu */}
        <SettingsNavMenu />
        <div className="flex flex-col gap-4 flex-grow mt-8">
          <div className="container mx-auto">
            <div className="max-w-[1040px] mx-auto">
              <div className="flex">
                <p className="font-semibold text-xl flex-grow">{selectTitle()}</p>
              </div>
              {/* Main content */}
              <div className="mt-6">{selectMainContent()}</div>
            </div>
          </div>
        </div>
      </div>
      <Toaster
        toastOptions={{
          style: {
            maxWidth: 500,
          },
        }}
      />
    </>
  )
}

export default SettingsWrapper
