import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface AdversariesState {}

const initialState: AdversariesState = {}

export const adversariesSlice = createSlice({
  name: 'adversaries',
  initialState,
  reducers: {},
})
export const selectBlocklist = (state: RootState) => state.blocklist

export default adversariesSlice.reducer
