/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import {
  Admin,
  ApiKey,
  AuditLog,
  BillingInfo,
  Plan,
  WebhookPayload,
  WebhookSecret,
  MembersResponse,
  SsoGroups,
  Scim,
  Mdm,
  EmailPreferences,
  NotificationPreferences,
  EnrollEmailTemplate,
  ThreatHunterUser,
  ConditionalAccessResponse,
  ConditionalAccessPolicyResponse,
} from '../../types/SettingsType'

export interface SettingsState {
  currentPage: string
  admins: Admin[] | undefined
  auditLogs: AuditLog[] | undefined
  billingInfo: BillingInfo | undefined
  planInfo: Plan | undefined
  ssoGroups: SsoGroups | undefined
  ssoMembers: MembersResponse | undefined
  apiKey: ApiKey | undefined
  webhookSecret: WebhookSecret | undefined
  webhook: WebhookPayload | undefined
  conditionalAccess: ConditionalAccessResponse | undefined
  conditionalAccessPolicy: ConditionalAccessPolicyResponse | undefined
  scim: Scim | undefined
  mdm: Mdm | undefined
  emailPreference: EmailPreferences | undefined
  notificationPreference: NotificationPreferences | undefined
  emailEnrollmentTemplate: EnrollEmailTemplate | undefined
  threatHunterUsers: ThreatHunterUser[] | undefined
  auditLogsTotalPages: number
  auditLogsCurrentPage: number
}

const initialState: SettingsState = {
  currentPage: 'SSO',
  admins: undefined,
  auditLogs: undefined,
  billingInfo: undefined,
  planInfo: undefined,
  ssoGroups: undefined,
  ssoMembers: undefined,
  apiKey: undefined,
  webhookSecret: undefined,
  webhook: undefined,
  conditionalAccess: undefined,
  conditionalAccessPolicy: undefined,
  scim: undefined,
  mdm: undefined,
  emailPreference: undefined,
  notificationPreference: undefined,
  emailEnrollmentTemplate: undefined,
  threatHunterUsers: undefined,
  auditLogsTotalPages: 1,
  auditLogsCurrentPage: 0,
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<any>) {
      state.currentPage = action.payload
    },
    setAdmins(state, action: PayloadAction<any>) {
      state.admins = action.payload
    },
    setAuditLogs(state, action: PayloadAction<any>) {
      state.auditLogs = action.payload
    },
    setBillingInfo(state, action: PayloadAction<any>) {
      state.billingInfo = action.payload
    },
    setPlanInfo(state, action: PayloadAction<any>) {
      state.planInfo = action.payload
    },
    setSsoGroups(state, action: PayloadAction<any>) {
      state.ssoGroups = action.payload
    },
    setSsoMembers(state, action: PayloadAction<any>) {
      state.ssoMembers = action.payload
    },
    setApiKey(state, action: PayloadAction<any>) {
      state.apiKey = action.payload
    },
    setWebhookSecret(state, action: PayloadAction<any>) {
      state.webhookSecret = action.payload
    },
    setWebhook(state, action: PayloadAction<any>) {
      state.webhook = action.payload
    },
    setConditionalAccess(state, action: PayloadAction<any>) {
      state.conditionalAccess = action.payload
    },
    setConditionalAccessPolicy(state, action: PayloadAction<any>) {
      state.conditionalAccessPolicy = action.payload
    },
    setScim(state, action: PayloadAction<any>) {
      state.scim = action.payload
    },
    setMdm(state, action: PayloadAction<any>) {
      state.mdm = action.payload
    },
    setEmailPreference(state, action: PayloadAction<any>) {
      state.emailPreference = action.payload
    },
    setNotificationPreference(state, action: PayloadAction<any>) {
      state.notificationPreference = action.payload
    },
    setEmailEnrollmentTemplate(state, action: PayloadAction<any>) {
      state.emailEnrollmentTemplate = action.payload
    },
    setThreatHunterUsers(state, action: PayloadAction<any>) {
      state.threatHunterUsers = action.payload
    },
    setAuditLogsTotalPages(state, action: PayloadAction<any>) {
      state.auditLogsTotalPages = action.payload
    },
    setAuditLogsCurrentPage(state, action: PayloadAction<any>) {
      state.auditLogsCurrentPage = action.payload
    },
  },
})
export const selectSettings = (state: RootState) => state.settings

export const {
  setCurrentPage,
  setAdmins,
  setAuditLogs,
  setBillingInfo,
  setPlanInfo,
  setApiKey,
  setWebhookSecret,
  setWebhook,
  setSsoGroups,
  setSsoMembers,
  setScim,
  setMdm,
  setEmailPreference,
  setNotificationPreference,
  setEmailEnrollmentTemplate,
  setThreatHunterUsers,
  setConditionalAccess,
  setConditionalAccessPolicy,
  setAuditLogsTotalPages,
  setAuditLogsCurrentPage,
} = settingsSlice.actions
export default settingsSlice.reducer
