/* eslint-disable @typescript-eslint/no-explicit-any */

export interface Integration {
  pageState: PageState
  integrations: IntegrationDetails[]
}

export enum PageState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface IntegrationDetails {
  name: string
  description: string
  image: string
  serverValue: string
  docsLink: string
  configurationOptions: ConfigurationOption[]
  iverifyOptions: ConfigurationOption[]
  required?: boolean
  min?: number
  max?: number
  format?: string
}

export interface ConfigurationOption {
  label: string
  type: string
  serverValue: string
  placeholder: string
  options: PortalOptionObject[]
  value: any
  required?: boolean
  minLength?: number
  maxLength?: number
  format?: string
}

export interface PortalOptionObject {
  label: string
  value: string
}

export interface UpdateIntegrationPayload {
  integrationValues: string
  integrationServerValue: string
}

export interface IntegrationValue {
  serverValue: string
  value: string
}

export interface DeleteIntegrationPayload {
  integrationServerValue: string
}
