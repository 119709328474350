import { portalNavItems, fleetNavItems, otherNavItems } from '../../data/settings-nav-items'
import { useDispatch, useSelector } from 'react-redux'
import { selectSettings, setCurrentPage } from '../../reducers/settings'
import { selectUser } from '../../reducers/users'
import { getRoles, permitsRole } from '../../utils/roles'
import { IconChevronLeft } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'

function SettingsNavMenu() {
  const { currentPage } = useSelector(selectSettings)
  const { session } = useSelector(selectUser)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const roles = getRoles(session)

  const filteredProfileNavItems = portalNavItems.filter((nav) => roles && permitsRole(roles, nav.roles))
  const filteredFleetNavItems = fleetNavItems.filter((nav) => roles && permitsRole(roles, nav.roles))
  const filteredOtherNavItems = otherNavItems.filter((nav) => roles && permitsRole(roles, nav.roles))

  return (
    <>
      {/* Nav menu */}
      <div className="w-[220px] h-full bg-white border-r border-neutral-5 pl-8">
        <div className="flex mt-8">
          <div
            onClick={() => {
              localStorage.removeItem('SETTINGS_PAGE_COOKIE')
              navigate('/')
            }}
            role="button"
          >
            <IconChevronLeft className="h-6 w-6 text-black mr-2.5 mt-0.5" />
          </div>
          <p className="text-black text-lg font-semibold">Settings</p>
        </div>
        <div className="w-[190px] mt-8">
          {filteredProfileNavItems.length > 0 && (
            <>
              <p className="text-neutral-10 text-xs leading-none tracking-tight font-semibold mb-1">Portal</p>
              {filteredProfileNavItems.map((item) => (
                <p
                  key={item.type}
                  role="button"
                  className={`w-[190px] ${currentPage === item.type ? 'text-PurpleBlue-9' : 'text-neutral-12'} justify-start cursor-pointer mt-3 font-medium`}
                  onClick={() => {
                    dispatch(setCurrentPage(item.type))
                    localStorage.setItem('SETTINGS_PAGE_COOKIE', item.type)
                  }}
                >
                  {item.display}
                </p>
              ))}
            </>
          )}

          <p className="text-neutral-10 text-xs leading-none tracking-tight font-semibold mt-8">Fleet Management</p>
          {filteredFleetNavItems.map((item) => (
            <p
              key={item.type}
              role="button"
              className={`w-[190px] ${currentPage === item.type ? 'text-PurpleBlue-9' : 'text-neutral-12'} justify-start cursor-pointer mt-3 font-medium`}
              onClick={() => {
                dispatch(setCurrentPage(item.type))
                localStorage.setItem('SETTINGS_PAGE_COOKIE', item.type)
              }}
            >
              {item.display}
            </p>
          ))}

          {session && session.portalPreferences.threatHunterAccess && filteredOtherNavItems.length > 0 && (
            <>
              <p className="text-neutral-10 text-xs leading-none tracking-tight font-semibold mt-8">Other</p>
              {filteredOtherNavItems.map((item) => (
                <p
                  key={item.type}
                  role="button"
                  className={`w-[190px] ${currentPage === item.type ? 'text-PurpleBlue-9' : 'text-neutral-12'} justify-start cursor-pointer mt-3 font-medium`}
                  onClick={() => {
                    dispatch(setCurrentPage(item.type))
                    localStorage.setItem('SETTINGS_PAGE_COOKIE', item.type)
                  }}
                >
                  {item.display}
                </p>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default SettingsNavMenu
