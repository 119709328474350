import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import {
  deactivateWebhook,
  fetchWebhook,
  fetchWebhookSecret,
  saveWebhook,
  selectSettings,
} from '../../reducers/settings'
import { useSelector } from 'react-redux'
import { Button, Card, Flex, IconButton, Separator, Switch, Text } from '@radix-ui/themes'
import { CopyIcon, EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons'
import IntegrationModal from './IntegrationModal'
import toast from 'react-hot-toast'
import { WebhookResponse } from '../../types/SettingsType'
import { Input } from '../input/Input'
import { selectUser } from '../../reducers/users'
import { RoleType } from '../../types/UserTypes'
import { getRoles, permitsRole } from '../../utils/roles'

function WebhookSettings() {
  const { webhookSecret, webhook } = useSelector(selectSettings)
  const [showWebhookKey, setShowWebhookKey] = useState<boolean>(false)
  const [showWebhook, setShowWebhook] = useState<boolean>(false)
  const [showRevokeModal, setShowRevokeModal] = useState<boolean>(false)
  const [screenToShow, setScreenToShow] = useState<string>('REVOKE')
  const [webhookURL, setWebhookURL] = useState<string>('')
  const [webhookValidating, setWebhookValidating] = useState<boolean>(false)
  const di = useAppDispatch()
  const { session } = useSelector(selectUser)

  const roles = getRoles(session)
  const rolePermitsWebhookPolicy = permitsRole(roles, [RoleType.PLATFORM_MGMT])

  useEffect(() => {
    if (rolePermitsWebhookPolicy) {
      fetchWebhookData()
    }
    if (roles && !rolePermitsWebhookPolicy) {
      window.location.href = '/forbidden'
    }
  }, [session])

  const saveWebhookDetails = async () => {
    setWebhookValidating(true)
    const response = (await di(saveWebhook({ secret: webhookSecret!.secret, url: webhookURL }))) as WebhookResponse
    if (!response.success) {
      toast.error(response.message, { duration: 6000 })
    } else {
      toast.success('Webhook successfully saved', { duration: 6000 })
    }
    setWebhookValidating(false)
  }

  const fetchWebhookData = async () => {
    const webhook = await di(fetchWebhook())
    if (!webhook) {
      await di(fetchWebhookSecret())
    } else {
      setWebhookURL(webhook.url)
      setShowWebhook(true)
    }
  }

  const toggleWebhook = (e: boolean) => {
    if (!e && webhook) {
      setShowWebhook(!showWebhook)
      setScreenToShow('WEBHOOK')
      setShowRevokeModal(true)
    } else {
      setShowWebhook(!showWebhook)
    }
  }

  const callDeactivateWebhook = async () => {
    await di(deactivateWebhook())
    setWebhookURL('')
    toast.success('Webhook successfully deactivated', { duration: 6000 })
  }

  const cancelDeactivateWebhook = () => {
    setShowWebhook(!showWebhook)
  }

  return (
    <>
      <div className="w-full h-[calc(100vh_-_80px)] overflow-auto">
        {/* Webhook settings */}
        {rolePermitsWebhookPolicy && (
          <Flex direction="column" gap="3" maxWidth="100%">
            <Card variant="surface">
              <Flex justify="between">
                <Text as="div" size="2" weight="bold">
                  Webhook Settings
                </Text>
                <Switch size="2" checked={showWebhook} onCheckedChange={(e) => toggleWebhook(e)} />
              </Flex>
              {showWebhook && (
                <>
                  <Separator my="3" size="4" />
                  <div className="flex mt-2 justify-between">
                    <div className="w-full mr-4">
                      <Text as="div" size="1" className="mt-2" color="gray">
                        Webhook Secret
                      </Text>
                      <div className="h-[42px] flex mt-1 border-InputBorderGrey border rounded px-2 py-2.5 justify-between text-sm">
                        {showWebhookKey ? webhookSecret?.secret : '*********************'}
                        <div className="flex mt-0.5">
                          <IconButton
                            size="2"
                            variant="ghost"
                            className="mr-1"
                            onClick={() => setShowWebhookKey(!showWebhookKey)}
                          >
                            {showWebhookKey ? (
                              <EyeNoneIcon height="16" width="16" />
                            ) : (
                              <EyeOpenIcon height="16" width="16" />
                            )}
                          </IconButton>
                          <IconButton
                            size="2"
                            variant="ghost"
                            onClick={() => {
                              navigator.clipboard.writeText(webhookSecret!.secret)
                              toast.success('API Key copied to clipboard', { duration: 6000 })
                            }}
                          >
                            <CopyIcon height="16" width="16" />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                    <div className="w-full mr-4">
                      <Text as="div" size="1" className="mt-2 mb-1" color="gray">
                        Webhook URL
                      </Text>
                      <Input type="text" value={webhookURL} onChange={setWebhookURL} />
                    </div>
                    <Button
                      variant="solid"
                      size="3"
                      className={`h-9 w-[200px] bg-PurpleBlue-10 text-white cursor-pointer text-sm font-semibold mt-7`}
                      onClick={() => saveWebhookDetails()}
                      disabled={webhookValidating}
                    >
                      {webhookValidating ? 'Validating...' : 'Save'}
                    </Button>
                  </div>
                  <Text as="div" color="gray" size="2" className="mt-4">
                    Read{' '}
                    <span className="text-PurpleBlue-10 cursor-pointer">
                      <a href="https://api-docs.iverify.io/iverify-webhooks" target="_blank">
                        Webhooks Setup Documentation
                      </a>
                    </span>
                  </Text>
                </>
              )}
            </Card>
          </Flex>
        )}
      </div>
      {showRevokeModal && (
        <IntegrationModal
          setShowIntegrationModal={setShowRevokeModal}
          screenToShow={screenToShow}
          deactivateWebhook={callDeactivateWebhook}
          cancelDeactivateWebhook={cancelDeactivateWebhook}
        />
      )}
    </>
  )
}

export default WebhookSettings
