import { validGuidRequired, validMaxLength, validMinLength, valueRequired } from '../schemas/IntegrationSchema'

export function validateField(field: {
  value: string
  label?: string
  format?: string
  required?: boolean
  minLength?: number
  maxLength?: number
}): string {
  let error = ''
  try {
    switch (field.format) {
      case 'guid':
        validGuidRequired(field.label).validateSync({ value: field.value })
        break
      default:
        field.required ? valueRequired(field.label).validateSync({ value: field.value }) : null
        break
    }
    if (field.maxLength) validMaxLength(field.label, field.maxLength).validateSync({ value: field.value })
    if (field.minLength) validMinLength(field.label, field.minLength).validateSync({ value: field.value })
  } catch (e) {
    if (e instanceof Error) {
      error = e.message
    }
  }
  return error
}
