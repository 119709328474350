import toast from 'react-hot-toast'
import { downloadAdversaryReportPdf } from '../../reducers/adversaries'
import { useAppDispatch } from '../../reducers/store'
import adversariesStructure from './adversaries_structure.json'
import imageMapping from './imageMapping'

type ReportName = keyof typeof imageMapping

function AdversariesWrapper() {
  const di = useAppDispatch()

  const adversariesWithImages = adversariesStructure.map((adversaryGroup) => ({
    ...adversaryGroup,
    reports: adversaryGroup.reports.map((report) => ({
      ...report,
      reportImage:
        (report.reportName as ReportName) in imageMapping
          ? imageMapping[report.reportName as ReportName]
          : report.reportImage,
    })),
  }))

  const downloadAdversaryReport = async (reportName: string) => {
    const downloaded = await di(downloadAdversaryReportPdf(reportName))
    downloaded
      ? toast.success('Report successfully downloaded', { duration: 6000 })
      : toast.error('Failed to download report, please try again', { duration: 6000 })
  }

  return (
    <>
      <div className="absolute top-18 left-52 rounded-tl-lg border-t border-l bg-neutral-3 w-[calc(100%_-_208px)] h-[calc(100%_-_74px)] p-9 overflow-y-auto">
        <div className="mx-auto max-w-[924px]">
          {adversariesWithImages.map((adversaryGroup) => (
            <div className="mb-6" key={adversaryGroup.sectionName}>
              <p className="text-lg font-medium">{adversaryGroup.sectionName}</p>
              <div className="grid grid-cols-2 gap-3 mt-3">
                {adversaryGroup.reports.map((report) => (
                  <div
                    key={report.reportName}
                    className="border rounded-lg p-4 bg-white grid grid-rows-[1fr_auto] h-full"
                  >
                    <div>
                      <img
                        src={report.reportImage}
                        alt={report.reportName}
                        className="w-full h-[137px] object-cover rounded"
                      />
                      <p className="font-medium mt-2.5">{report.reportName}</p>
                      <p className="text-xs mt-3">{report.execSummary}</p>
                    </div>
                    <div className="flex justify-end mt-3">
                      <div
                        role="button"
                        className="h-8 px-3 bg-neutral-1 rounded border border-neutral-5 justify-center items-center gap-2 inline-flex"
                        onClick={() => downloadAdversaryReport(report.downloadLink)}
                      >
                        <div className="text-neutral-12 text-sm leading-tight">Download Profile</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default AdversariesWrapper
