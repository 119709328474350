import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import {
  deleteConditionalAccess,
  fetchConditionalAccessConfig,
  fetchConditionalAccessPolicy,
  postConditionalAccessConfig,
  postDisableConditionalAccess,
  selectSettings,
  setConditionalAccess,
  setConditionalAccessPolicy,
} from '../../reducers/settings'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Flex, Switch, Text } from '@radix-ui/themes'
import IntegrationModal from './IntegrationModal'
import toast from 'react-hot-toast'
import { ConditionalAccessResponse } from '../../types/SettingsType'
import ConditionAccessOptions from './ConditionAccessOptions'
import { selectUser } from '../../reducers/users'
import { RoleType } from '../../types/UserTypes'
import { getRoles, permitsRole } from '../../utils/roles'

function ConditionalAccessSettings() {
  const { conditionalAccess } = useSelector(selectSettings)
  const [showConditionalAccess, setShowConditionalAccess] = useState<boolean>(false)
  const [showRevokeModal, setShowRevokeModal] = useState<boolean>(false)
  const [screenToShow, setScreenToShow] = useState<string>('REVOKE')
  const di = useAppDispatch()
  const dispatch = useDispatch()
  const { session } = useSelector(selectUser)

  const roles = getRoles(session)
  const rolePermitsConditionalAccessPolicy = permitsRole(roles, [RoleType.ACCESS_MGMT])

  useEffect(() => {
    if (rolePermitsConditionalAccessPolicy) {
      fetchConditionalAccessData()
    }

    if (roles && !rolePermitsConditionalAccessPolicy) {
      window.location.href = '/forbidden'
    }
  }, [session])

  const fetchConditionalAccessData = async () => {
    const conditionalAccess = await di(fetchConditionalAccessConfig())
    const conditionalAccessPolicy = await di(fetchConditionalAccessPolicy())
    if (conditionalAccess) {
      dispatch(setConditionalAccess(conditionalAccess))
      conditionalAccess.active ? setShowConditionalAccess(true) : undefined
    }
    if (conditionalAccessPolicy) {
      dispatch(setConditionalAccessPolicy(conditionalAccessPolicy))
    }
  }

  const toggleConditionalAccess = (e: boolean) => {
    setShowConditionalAccess(!showConditionalAccess)
    if (!e && conditionalAccess) {
      setScreenToShow('CONDITIONAL_ACCESS_DISABLE')
      setShowRevokeModal(true)
    } else {
      setConditionalAccessConfigActive()
      setShowConditionalAccess(!showConditionalAccess)
    }
  }

  const removeConditionalAccess = async () => {
    await di(deleteConditionalAccess())
    const policy = await di(fetchConditionalAccessPolicy())
    dispatch(setConditionalAccess(undefined))
    dispatch(setConditionalAccessPolicy(policy))
    setShowConditionalAccess(false)
    toast.success('Conditional Access successfully removed', { duration: 6000 })
  }

  const disableConditionalAccess = async () => {
    const inactiveBody: ConditionalAccessResponse = {
      ...conditionalAccess!,
      active: false,
    }
    const disabled = await di(postDisableConditionalAccess(inactiveBody))
    dispatch(setConditionalAccess(inactiveBody))
    setShowConditionalAccess(false)

    disabled
      ? toast.success('Conditional Access successfully disabled', { duration: 6000 })
      : toast.error('Unable to disable Conditional Access. Please try again', { duration: 6000 })
  }

  const cancelRemoveConditionalAccess = () => {
    setShowConditionalAccess(!showConditionalAccess)
  }

  const setConditionalAccessConfigActive = async () => {
    if (conditionalAccess) {
      const activeBody: ConditionalAccessResponse = {
        ...conditionalAccess,
        active: true,
      }
      const successfulSave = await di(postConditionalAccessConfig(activeBody))
      if (successfulSave) {
        const policy = await di(fetchConditionalAccessPolicy())
        if (policy) {
          dispatch(setConditionalAccessPolicy(policy))
          toast.success('Conditional Access Active', { duration: 6000 })
        }
      }
    }
  }

  return (
    <>
      <div className="w-full h-[calc(100vh_-_80px)] overflow-auto">
        {/* CAC settings */}
        {rolePermitsConditionalAccessPolicy && (
          <Flex direction="column" gap="3" maxWidth="100%">
            <Card variant="surface">
              <Flex justify="between">
                <Text as="div" size="2" weight="bold">
                  Conditional Access
                </Text>
                <Switch size="2" checked={showConditionalAccess} onCheckedChange={(e) => toggleConditionalAccess(e)} />
              </Flex>
              {showConditionalAccess && (
                <ConditionAccessOptions setScreenToShow={setScreenToShow} setShowRevokeModal={setShowRevokeModal} />
              )}
            </Card>
          </Flex>
        )}
      </div>
      {showRevokeModal && (
        <IntegrationModal
          setShowIntegrationModal={setShowRevokeModal}
          screenToShow={screenToShow}
          removeConditionalAccess={removeConditionalAccess}
          disableConditionalAccess={disableConditionalAccess}
          cancelRemoveConditionalAccess={cancelRemoveConditionalAccess}
        />
      )}
    </>
  )
}

export default ConditionalAccessSettings
