import * as yup from 'yup'
import { GUID_REGEX } from '../strings/yup-regex'

export const valueRequired = (field: string = 'This') =>
  yup.object().shape({
    value: yup.string().required(`${field} is a required field`),
  })

export const validGuidRequired = (field: string = 'This') =>
  yup.object().shape({
    value: yup.string().required(`${field} is a required field`).matches(GUID_REGEX, `Invalid ${field}`),
  })

export const validMinLength = (field: string = 'This', length: number) =>
  yup.object().shape({
    value: yup.string().min(length, `${field} must not be less than ${length} characters`),
  })

export const validMaxLength = (field: string = 'This', length: number) => 
  yup.object().shape({
    value: yup.string().max(length, `${field} must not be greater than ${length} characters`),
  })