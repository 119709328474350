import { IntegrationDetails } from '../../types/IntegrationTypes'

interface IIntegrationCardProps {
  integration: IntegrationDetails
  integrationName: string
  integrationDescription: string
  logoUrl: string
  docsUrl: string
  manageIntegration: (integration: IntegrationDetails) => void
  notConfigured?: boolean
}

function IntegrationCard(props: IIntegrationCardProps) {
  const { integration, integrationName, integrationDescription, logoUrl, docsUrl, manageIntegration, notConfigured } =
    props

  return (
    <div className="w-full my-3 p-4 bg-white border rounded-lg flex justify-between">
      <div className="block">
        <div className="flex mb-4">
          <img src={logoUrl} alt="integration logo" className="w-8 h-8" />
          <p className="ml-4 text-black text-base font-medium mt-1">{integrationName}</p>
        </div>
        <p className="text-black text-sm font-normal max-w-[678px]">{integrationDescription}</p>
      </div>
      <div className="flex my-auto">
        <div onClick={() => manageIntegration(integration)} className="h-8 px-3 bg-neutral-1 rounded border border-neutral-5 justify-center items-center gap-2 inline-flex cursor-pointer">
          <div className="text-neutral-12 text-sm leading-tight" >
            {notConfigured ? 'Configure' : 'Manage'}
          </div>
        </div>
        <p
          className="text-sm text-neutral-12 underline ml-5 mt-1.5 cursor-pointer"
          onClick={() => window.open(docsUrl, '_blank')}
        >
          Docs
        </p>
      </div>
    </div>
  )
}

export default IntegrationCard
