/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../reducers/store'
import { generateAPIKey, fetchAPIKey, revokeAPIKey, selectSettings } from '../../reducers/settings'
import { useSelector } from 'react-redux'
import { Button, Card, Flex, IconButton, Separator, Text } from '@radix-ui/themes'
import Select from 'react-select'
import { CopyIcon, EyeNoneIcon, EyeOpenIcon } from '@radix-ui/react-icons'
import { formatForTimestampWithDate } from '../../utils/dates'
import IntegrationModal from './IntegrationModal'
import toast from 'react-hot-toast'
import { selectUser } from '../../reducers/users'
import { RoleType } from '../../types/UserTypes'
import { getRoles, permitsRole } from '../../utils/roles'

function ApiSettings() {
  const { apiKey } = useSelector(selectSettings)
  const [showKey, setShowKey] = useState<boolean>(false)
  const [showRevokeModal, setShowRevokeModal] = useState<boolean>(false)
  const [screenToShow, setScreenToShow] = useState<string>('REVOKE')
  const [expiry, setExpiry] = useState({ value: 'ONE_YEAR', label: '1 Year' })
  const di = useAppDispatch()
  const { session } = useSelector(selectUser)

  const options = [
    { value: 'SIX_MONTHS', label: '6 Months' },
    { value: 'ONE_YEAR', label: '1 Year' },
    { value: 'NEVER', label: 'Never' },
  ]

  const roles = getRoles(session)
  const rolePermitsAPIKeyPolicy = permitsRole(roles, [RoleType.PLATFORM_MGMT])

  useEffect(() => {
    if (rolePermitsAPIKeyPolicy) {
      di(fetchAPIKey())
    }

    if (roles && !rolePermitsAPIKeyPolicy) {
      window.location.href = '/forbidden'
    }
  }, [session])

  const revokeKey = () => {
    di(revokeAPIKey())
    toast.success('API Key successfully revoked', { duration: 6000 })
  }

  const generateKey = () => {
    if (apiKey) {
      setScreenToShow('REGENERATE')
      setShowRevokeModal(true)
    } else {
      di(generateAPIKey(expiry.value))
      toast.success('API Key successfully generated', { duration: 6000 })
    }
  }

  const regenerateKey = () => {
    di(generateAPIKey(expiry.value))
    toast.success('API Key successfully regenerated', { duration: 6000 })
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: '38px',
      minHeight: '38px',
    }),
    valueContainer: (base: any) => ({
      ...base,
      height: '30px',
      minHeight: '30px',
      paddingTop: '0px',
      paddingBottom: '0px',
    }),
    placeholder: (base: any) => ({
      ...base,
      height: '30px',
      minHeight: '30px',
      paddingTop: '0px',
    }),
    input: (base: any) => ({
      ...base,
      height: '36px',
      minHeight: '36px',
      marginTop: '0px',
      marginBottom: '0px',
      paddingTop: '0px',
    }),
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  }

  return (
    <>
      <div className="w-full h-[calc(100vh_-_80px)] overflow-auto">
        {/* API Key settings */}
        {rolePermitsAPIKeyPolicy && (
          <Flex direction="column" gap="3" maxWidth="100%" className="z-40">
            <Card variant="surface">
              <Text as="div" size="2" weight="bold">
                API Settings
              </Text>
              <Separator my="3" size="4" />
              {apiKey && (
                <div>
                  <Text as="div" size="1" className="mt-2" color="gray">
                    API Key
                  </Text>
                  <div className="w-full flex justify-between space-x-4">
                    <div className="h-[38px] flex mt-1 border-[#00000027] border rounded px-2 py-2.5 justify-between text-sm w-2/3">
                      {showKey ? apiKey.token : '*********************'}
                      <div className="flex">
                        <IconButton size="2" variant="ghost" className="mr-1" onClick={() => setShowKey(!showKey)}>
                          {showKey ? <EyeNoneIcon height="16" width="16" /> : <EyeOpenIcon height="16" width="16" />}
                        </IconButton>
                        <IconButton
                          size="2"
                          variant="ghost"
                          onClick={() => {
                            navigator.clipboard.writeText(apiKey.token)
                            toast.success('API Key copied to clipboard', { duration: 6000 })
                          }}
                        >
                          <CopyIcon height="16" width="16" />
                        </IconButton>
                      </div>
                    </div>
                    <Button
                      variant="solid"
                      className="h-[38px] w-1/3 bg-ErrorRed cursor-pointer mt-1 float-right"
                      onClick={() => {
                        setScreenToShow('REVOKE')
                        setShowRevokeModal(true)
                      }}
                    >
                      Revoke API Key
                    </Button>
                  </div>
                  <Text as="div" size="1" className="mt-1 " color="gray">
                    {apiKey.expiresAt
                      ? 'Expires on ' + formatForTimestampWithDate(apiKey.expiresAt)
                      : 'API Key set to not expire'}
                  </Text>
                </div>
              )}
              <Text as="div" size="1" className="mt-2" color="gray">
                Expiry
              </Text>
              <div className="flex mt-1">
                <Select
                  defaultValue={options.find((option) => option.value === 'ONE_YEAR')}
                  onChange={(e: any) => setExpiry(e)}
                  options={options}
                  className={'w-full mr-4 z-50'}
                  menuPortalTarget={document.body}
                  styles={{ ...customStyles }}
                />
                <Button
                  variant="solid"
                  className="h-[38px] w-1/3 bg-PurpleBlue-10 text-white cursor-pointer"
                  onClick={() => generateKey()}
                >
                  {apiKey ? 'Re-g' : 'G'}enerate API Key
                </Button>
              </div>
              {expiry.value !== 'ONE_YEAR' && (
                <Text as="div" color="gray" size="2" className="mt-4">
                  Consider choosing 1-year token to rotate credentials periodically
                </Text>
              )}
            </Card>
          </Flex>
        )}
      </div>
      {showRevokeModal && (
        <IntegrationModal
          setShowIntegrationModal={setShowRevokeModal}
          screenToShow={screenToShow}
          revokeKey={revokeKey}
          regenerateKey={regenerateKey}
        />
      )}
    </>
  )
}

export default ApiSettings
