import { RoleType } from '../types/UserTypes'

export const portalNavItems = [
  {
    type: 'ADMIN',
    display: 'Administrators',
    roles: [RoleType.SUPER_ADMIN],
  },
  {
    type: 'AUDIT',
    display: 'Audit Logs',
    roles: [RoleType.SUPER_ADMIN],
  },
  {
    type: 'SSO',
    display: 'SSO',
    roles: [RoleType.SUPER_ADMIN, RoleType.PLATFORM_MGMT],
  },
  {
    type: 'WEBHOOKS',
    display: 'Webhooks',
    roles: [RoleType.SUPER_ADMIN, RoleType.PLATFORM_MGMT],
  },
  {
    type: 'API',
    display: 'API',
    roles: [RoleType.SUPER_ADMIN, RoleType.PLATFORM_MGMT],
  },
]

export const fleetNavItems = [
  {
    type: 'SCIM',
    display: 'SCIM',
    roles: [RoleType.SUPER_ADMIN, RoleType.PLATFORM_MGMT],
  },
  {
    type: 'MDM',
    display: 'MDM',
    roles: [RoleType.SUPER_ADMIN, RoleType.PLATFORM_MGMT],
  },
  {
    type: 'NOTIFICATION',
    display: 'Notifications',
    roles: [RoleType.SUPER_ADMIN, RoleType.PLATFORM_MGMT],
  },
  {
    type: 'CONDITIONAL_ACCESS',
    display: 'Conditional Access',
    roles: [RoleType.SUPER_ADMIN, RoleType.PLATFORM_MGMT, RoleType.ACCESS_MGMT],
  },
]

export const otherNavItems = [
  {
    type: 'THREAT_HUNTER',
    display: 'Threat Hunter',
    roles: [RoleType.SUPER_ADMIN, RoleType.PLATFORM_MGMT],
  },
]

export type NavItems = {
  type: string
  display: string
}
